import { makeStyles, createStyles } from '@mui/styles';
import { CustomTheme } from '../../../services/Theme/types';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({

        EditModal: {
            overflowY: 'scroll',
        },

        DontScroll: {
            overflowY: 'hidden',
        },

        Card: {
            position: 'relative',
            // marginBottom: theme.spacing(1),
            maxHeight: 'calc(100dvh - 24px)',
            overflow: 'visible !important',
        },

        Header: {
            display: 'flex',
            width: '100%',
            justifyContent: 'space-between',
            alignItems: 'center',
            position: 'absolute',
            top: 0,
            borderTopLeftRadius: theme.shape.borderRadius,
            borderTopRightRadius: theme.shape.borderRadius,
        },

        ContentRoot: {
            borderRadius: theme.shape.borderRadius,
            paddingTop: 38,
        },

        Content: {
            maxHeight: 'calc(100dvh - 128px)',
            overflow: 'auto',
            height: '100%',
            width: '100%',
            paddingTop: theme.spacing(2),
        },

        Footer: {
            position: 'absolute',
            bottom: 0,
            width: '100%',
            borderBottomLeftRadius: theme.shape.borderRadius,
            borderBottomRightRadius: theme.shape.borderRadius,
        },

        ModalMedium: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '60%',
            minWidth: 340,

            [theme.breakpoints.down('md')]: {
                width: '96%',
            },
        },

        ModalSmall: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '30%',
            minWidth: 340,

            [theme.breakpoints.down('md')]: {
                width: '96%',
            },
        },

        ModalLarge: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '96%',
        },

        ModalFull: {
            width: '100dvw',
            height: '100dvh',
            padding: 0,
            margin: 0,
            '& .MuiCard-root': {
                height: '100dvh',
                maxHeight: '100dvh !important',
            }
        },

        ModalCustom: {
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
        },

        CloseBtn: {
            color: theme.others.iconColor,
            marginLeft: 'auto',
        },

        ContainerModal: {
            padding: theme.spacing(2),
            paddingBottom: theme.spacing(1),
            // overflowY: 'auto',
            // overflowX: 'hidden',
            maxHeight: 'calc(100dvh - 150px)',
        },

        ContainerModalNoScroll: {
            padding: theme.spacing(2),
            paddingBottom: theme.spacing(1),
            overflowY: 'hidden',
            overflowX: 'hidden',
            maxHeight: 'calc(100dvh - 150px)',
        },

        ContainerModalFull: {
            padding: theme.spacing(2),
            paddingBottom: theme.spacing(1),
            overflowY: 'hidden',
            overflowX: 'hidden',
        },



        ContainerRootFullHeight: {
            height: '100dvh !important',
            maxHeight: 'unset !important',
        },

        ContainerFullHeight: {
            maxHeight: 'unset !important',
        },

        ContentInsideFullHeight: {
            height: '100%',
            paddingBottom: theme.spacing(1) + ' !important',
        },

        ContainerFull: {
            maxHeight: 'calc(100dvh - 115px) !important',
            height: '100%',
        }
    }),
);

export default useStyles;