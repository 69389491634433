import { Button, ButtonPropsColorOverrides, Hidden, PropTypes, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import useStyles from './styles';
import { OverridableStringUnion } from '@mui/types';

type MuiButtonColors = OverridableStringUnion<
    'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning',
    ButtonPropsColorOverrides
>;
type NewColor = 'default' | 'red' | 'green';

type ButtonProps = {
    type?: "button" | "reset" | "submit" | undefined;
    label?: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    icon?: React.ReactNode;
    color?: MuiButtonColors | NewColor;
    shrink?: boolean;
    variant?: 'text' | 'outlined' | 'contained';
    disabled?: boolean;
    className?: string;
    size?: 'small' | 'medium' | 'large'
    children?: any;
    tabIndex?: number;
}

const ColorButton: React.FC<ButtonProps> = ({ label, type, onClick, icon, color, shrink, variant, disabled, children, className, size, tabIndex }) => {
    const classes = useStyles();

    const theme = useTheme();
    const hideMdUp = !useMediaQuery(theme.breakpoints.up('sm'));
    const hideSmDown = !useMediaQuery(theme.breakpoints.down('sm'));

    let rootClass;
    switch (color) {
        case 'red':
            rootClass = classes.rootRedButton;
            break;

        case 'green':
            rootClass = classes.rootGreenButton;
            break;

        case undefined:
        case 'default':
            rootClass = classes.defaultButton;
    }

    const _color = (color !== 'red' && color !== 'green' && color !== 'default') ? (color as MuiButtonColors) : undefined;

    const getChildren = () => {
        if (label)
            return (<Typography className={classes.Button}>{label}</Typography>)
        return children;
    }

    return shrink ?
        (<>
            {hideSmDown &&
                <Button
                    tabIndex={tabIndex}
                    type={type}
                    classes={{ root: rootClass }}
                    variant={variant || "contained"}
                    color={_color}
                    size={size}
                    className={'BGColorButton ' + classes.TitleButton + ' ' + classes.Shrink + ' ' + className}
                    startIcon={!!children || !!label ? icon : undefined}
                    disabled={disabled}
                    onClick={onClick}>
                    {!!children || !!label ? getChildren() : icon}
                </Button>
            }
            {hideMdUp &&
                <Button
                    tabIndex={tabIndex}
                    type={type}
                    classes={{ root: rootClass }}
                    variant={variant || "contained"}
                    color={_color}
                    size={size}
                    className={'BGColorButton ' + classes.TitleButton + ' ' + classes.Shrink + ' ' + className}
                    disabled={disabled}
                    onClick={onClick}>
                    {icon}
                </Button>
            }
        </>)
        :
        <Button
            tabIndex={tabIndex}
            type={type}
            classes={{ root: rootClass }}
            variant={variant || "contained"}
            color={_color}
            size={size}
            className={'BGColorButton ' + classes.TitleButton + ' ' + (size === 'small' && classes.SizeSmall) + ' ' + (size === 'medium' || size === undefined && classes.SizeMedium) + ' ' + className}
            startIcon={!!children || !!label ? icon : undefined}
            disabled={disabled}
            onClick={onClick}>
            {!!children || !!label ? getChildren() : icon}
        </Button>;
}

export default ColorButton;
