import { makeStyles, createStyles } from '@mui/styles';
import { CustomTheme } from '../../../../services/Theme/types';

const useStyles = makeStyles((theme: CustomTheme) =>
  createStyles({

    Item: {
        fontSize: '0.85rem'
    },
    FlagIcon: {
        '& svg' : {
            width: 26,
            height: 26,
        },
    }
  }),
);

export default useStyles;