import React, { CSSProperties, useState } from "react";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { DialogOption, ProviderContext, DialogParams, DialogContainerProps, DialogButtonType, DialogButtonVariant, DialogButtonColor, DialogSize } from './types';
import ColorButton from "../../components/ui/ColorButton";
import { useLang } from "../Lang";
import { Close } from "@mui/icons-material";
import useStyles from "./styles";
import { useDraggable } from "@dnd-kit/core";
import { CSS } from '@dnd-kit/utilities';

const EMPTY_FUNC = () => { };
const DialogContext = React.createContext<ProviderContext>([EMPTY_FUNC, EMPTY_FUNC]);

export const useDialog = () => React.useContext(DialogContext);

export type DoCloseFunction = () => void;

export type DialogContentFuncion = (doClose: DoCloseFunction) => any;

export type DialogHelper = {
    onOk?: () => any;
    onCancel?: () => any;
    setSubmitting?: React.Dispatch<React.SetStateAction<boolean>>;
    [key: string]: any
}

export type DialogProps = {
    doClickCancel: () => void
    doClickOk: () => void
    helper: DialogHelper
}


const DialogContainer = (props: DialogContainerProps) => {
    const { open, onClose, onExited, title, content, buttons, fullHeight, captions, colors, icons, variants, size, position, style, onClickOk, onClickYes, onClickNo, onClickCancel, helper } = props;
    const { lang } = useLang();
    const [btns] = useState<any>({});

    const classes = useStyles();

    const doClickOk = () => {
        if (onClickOk) onClickOk();
        if (onClose) onClose();
    }
    const doClickYes = () => {
        if (onClickYes) onClickYes();
        if (onClose) onClose();
    }
    const doClickNo = () => {
        if (onClickNo) onClickNo();
        if (onClose) onClose();
    }
    const doClickCancel = () => {
        if (onClickCancel) onClickCancel();
        if (onClose) onClose();
    }

    let _buttons: DialogButtonType[] = ['ok'];
    if (buttons)
        _buttons = buttons;
    let _captions: string[] = [];
    if (captions)
        _captions = captions;
    let _variants: DialogButtonVariant[] = [];
    if (variants)
        _variants = variants;
    let _colors: DialogButtonColor[] = [];
    if (colors)
        _colors = colors;
    let _icons: React.ReactNode[] = [];
    if (icons)
        _icons = icons;
    let _size: DialogSize = 'sm';
    if (size)
        _size = size;

    const getButtons = () => {
        return _buttons.map((b, i) => {
            let variant = _variants[i];
            if (!variant)
                variant = 'outlined';
            let color = _colors[i];
            if (!color)
                color = 'default';
            const icon = _icons[i];
            let caption: string = lang.data.captions.ok;
            let click = doClickOk;
            switch (b) {
                case 'yes':
                    btns.yes = true;
                    caption = lang.data.captions.yes;
                    click = doClickYes;
                    break;
                case 'no':
                    btns.no = true;
                    caption = lang.data.captions.no;
                    click = doClickNo;
                    break;
                case 'cancel':
                    btns.cancel = true;
                    caption = lang.data.captions.cancel;
                    click = doClickCancel;
                    break;
                case 'ok':
                    btns.ok = true;
                    break;
            }
            if (_captions[i])
                caption = _captions[i];
            return <ColorButton className={'BGDialog_' + b} key={i} color={color} variant={variant} onClick={click} icon={icon}>{caption}</ColorButton>
        })
    }

    const onKeyDown = (evt: React.KeyboardEvent<HTMLElement>) => {
        if (evt.key === 'Enter') {
            if (btns.ok)
                return doClickOk();
            if (btns.yes)
                return doClickYes();
        } else if (evt.key === 'Escape') {
            if (btns.cancel)
                return doClickCancel();
            if (btns.no)
                return doClickNo();
            return doClickCancel();
        }
    }


    const { attributes, listeners, transform, isDragging, setNodeRef } = useDraggable({
        id: 'dialog',
    });

    const draggableStyle = {
        transform: CSS.Translate.toString(transform)
    };

    const styleTop: CSSProperties = position === 'top' ? {
        position: 'absolute',
        top: 0,
    } : {};


    return (
        <Dialog
            open={open}
            onClose={doClickCancel}
            slotProps={{
                transition: { onExit: onExited },

                paper: {
                    style: { ...draggableStyle, ...styleTop },
                    id: 'dialog',
                    ...attributes,
                }
            }}
            fullWidth={true}
            maxWidth={_size}
            onKeyDown={onKeyDown}
        >

            {!!title && <DialogTitle className={classes.Title}>
                <div ref={setNodeRef} style={{ width: '100%', cursor: isDragging ? 'grabbing' : 'grab' }}  {...listeners}>
                    {title}
                </div>
                <IconButton aria-label="Close" size="small" onClick={doClickCancel}><Close /></IconButton>
            </DialogTitle>}

            <DialogContent className={classes.Content + ' ' + (fullHeight ? classes.ContentFull : '')} >
                {typeof content === 'function'
                    ? React.createElement(content, { doClickCancel, doClickOk, helper })
                    : content}
            </DialogContent>

            {!!buttons?.length && <DialogActions className={classes.Actions}>
                {getButtons()}
            </DialogActions>}
        </Dialog>
    );
}

const DialogProvider = ({ children }: { children: any }) => {

    const [dialogs, setDialogs] = React.useState<DialogParams[]>([]);

    const createDialog = (option: DialogOption) => {
        const dialog = { ...option, open: true };
        setDialogs((dialogs) => [...dialogs, dialog]);
    };

    const closeDialog = () => {
        setDialogs((dialogs) => {
            const latestDialog = dialogs.pop();
            if (!latestDialog)
                return dialogs;
            if (latestDialog.onClose)
                latestDialog.onClose();
            return [...dialogs].concat({ ...latestDialog, open: false });
        });
    };

    const contextValue = React.useRef([createDialog, closeDialog] as const);

    return (
        <DialogContext.Provider value={contextValue.current}>
            {children}

            {dialogs.map((dialog, i) => {
                const { onClose, onExited, ...dialogParams } = dialog;
                const handleKill = () => {
                    if (dialog.onExited) dialog.onExited();
                    setDialogs((dialogs) => dialogs.slice(0, dialogs.length - 1));
                };

                return (
                    <DialogContainer
                        key={i}
                        onClose={closeDialog}
                        onExited={handleKill}
                        {...dialogParams}
                    />
                );
            })}
        </DialogContext.Provider>
    );
}


export default DialogProvider;