import React, { createContext, useContext, useState } from 'react';
import { Api } from './Api';

const api: Api = new Api();

const ApiContext = createContext<Api>(api);

const ApiProvider = ({ children }: { children: any }) => {
    const [stateApi] = useState<Api>(api);
    return (
        <ApiContext.Provider value={stateApi}>
            {children}
        </ApiContext.Provider>
    );
}

const useApi = () => useContext(ApiContext);

export { useApi, ApiProvider };