import { CSSProperties } from 'react';
import { Grid2 } from '@mui/material';
import { GridSize } from '@mui/material/Grid';

interface ICol {
    lg?: GridSize;
    xs?: GridSize;
    sm?: GridSize;
    md?: GridSize;
    xl?: GridSize;
    visible?: boolean;
    style?: CSSProperties;
    className?: string;
    children?: any;
}
const Col = ({ lg, xs, sm, md, xl, visible, children, style, className }: ICol) => {
    return (visible === undefined || visible) && (
        <Grid2
            container
            spacing={2}
            size={{
                xs: xs || 12,
                sm: sm || xs || 12,
                md: md || sm || xs || 12,
                lg: lg || md || sm || xs || 12,
                xl: xl || lg || md || sm || xs || 12
            }}
            style={style}
            className={className}
            alignContent={'baseline'}
        >
            {children}
        </Grid2>
    );
}

export default Col;