import { createContext, useContext } from "react";

import { Lang } from "../Lang/types";
import { Navigator } from "react-router";

export type RouterBlocker = {
    navigator: Navigator;
    routesConfirmations: string[];
    lang: Lang;
    location: any;
    lastConfirmation?: number
    exitConfirmation: (route: string) => void;
    removeExitConfirmation: (route: string) => void;
};

export const RouterBlockerContext = createContext<RouterBlocker>({} as RouterBlocker);

export const useRouterBlock = () => useContext(RouterBlockerContext);

const confirmExit = (routerBlocker: RouterBlocker) => {
    return window.confirm(routerBlocker.lang.data.messages.cancelRecord);
}

export const setupRouterBlocker = (routerBlocker: RouterBlocker) => {

    routerBlocker.exitConfirmation = (route) => {
        setTimeout(() => {
            if (!routerBlocker.routesConfirmations.includes(route))
                routerBlocker.routesConfirmations.push(route);
        }, 100);
    };

    routerBlocker.removeExitConfirmation = (route) => {
        const index = routerBlocker.routesConfirmations.indexOf(route);
        if (index >= 0) {
            routerBlocker.routesConfirmations.splice(index, 1);
        }
    }

    window.onbeforeunload = () => {
        if (routerBlocker.routesConfirmations.includes(routerBlocker.location.pathname)) {
            return routerBlocker.lang.data.messages.cancelRecord;
        }
        return null;
    }

    const push = routerBlocker.navigator.push;

    routerBlocker.navigator.push = (...args: Parameters<typeof push>) => {
        const to: any = args[0];
        let result = true;

        if (to.pathname !== '/reconnect' && routerBlocker.routesConfirmations.includes(routerBlocker.location.pathname)) {
            if (Date.now() - (routerBlocker.lastConfirmation || 0) < 100) {
                return false;
            }
            result = confirmExit(routerBlocker);
            if (result) {
                routerBlocker.removeExitConfirmation(routerBlocker.location.pathname);
            } else {
                routerBlocker.lastConfirmation = Date.now();
            }
        }
        if (result !== false) {
            push(...args);
        }
    };

}