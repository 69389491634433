import { darken, styled } from '@mui/material';
import { MaterialDesignContent } from 'notistack'
import { CustomTheme } from '../../../services/Theme/types';

export const StyledMaterialDesignContent = styled(MaterialDesignContent)(({ theme: th }) => {

    const theme = th as CustomTheme;

    return {
        '&.notistack-MuiContent-success': {
            backgroundColor: theme.others.green,

            [theme.palette.mode === 'dark' ? '&' : '']: {
                backgroundColor: darken(theme.others.green, 0.8),
                border: '1px solid ' + theme.others.green
            },

        },

        '&.notistack-MuiContent-error': {
            backgroundColor: theme.others.red,
            [theme.palette.mode === 'dark' ? '&' : '']: {
                backgroundColor: darken(theme.others.red, 0.8),
                border: '1px solid ' + theme.others.red
            },
        },

        '&.notistack-MuiContent-warning': {
            backgroundColor: theme.others.organge,
            [theme.palette.mode === 'dark' ? '&' : '']: {
                backgroundColor: darken(theme.others.organge, 0.8),
                border: '1px solid ' + theme.others.organge
            },
        },

        '&.notistack-MuiContent-info': {
            backgroundColor: theme.others.blue,
            [theme.palette.mode === 'dark' ? '&' : '']: {
                backgroundColor: darken(theme.others.blue, 0.8),
                border: '1px solid ' + theme.others.blue
            },
        },

        '&.notistack-MuiContent-default': {
            backgroundColor: theme.others.blue,
            [theme.palette.mode === 'dark' ? '&' : '']: {
                backgroundColor: darken(theme.others.blue, 0.8),
                border: '1px solid ' + theme.others.blue
            },
        },
    }
});