import React, { CSSProperties } from 'react';
import { Grid2 } from '@mui/material';
import useStyles from './styles';

interface IRow {
    ref?: any;
    visible?: boolean;
    style?: CSSProperties;
    className?: string;
    children?: any;
}
const Row: React.FC<IRow> = ({ visible, style, children, className, ref }) => {

    const classes = useStyles();

    return (visible === undefined || visible) && (
        <Grid2 ref={ref} container spacing={2} alignContent={'baseline'} size={12} style={style} className={classes.root + ' BGRow ' + className}>
            {children}
        </Grid2>
    );
}

export default Row;