import { Helmet } from 'react-helmet';
import { Button, Grid2, Typography } from '@mui/material';
import Config from '../../../config';
import { useState } from 'react';
import { useApi } from '../../services/Api';
import { ReactComponent as Construction } from '../../../icons/construction.svg';
import { useTheme } from '../../services/Theme';

const ServerOffline = () => {
    const [lastPath] = useState(() => localStorage.getItem('lastPath') || '/');
    const api = useApi();
    const { theme } = useTheme()

    const doReconnect = () => {
        localStorage.setItem('lastPath', '');
        window.location.pathname = lastPath;
    }

    return (
        <>
            <Helmet>
                <title>{api.isStandAlone ? '' : Config.name}</title>
            </Helmet>
            <Grid2 container size={12} alignItems={'center'} style={{ height: '100vh' }}>
                <Grid2 flexGrow={1}></Grid2>
                <Grid2 size={{ xs: 12, sm: 12, md: 8, lg: 4, xl: 4 }} >
                    <Typography style={{ margin: 24, fontSize: '3rem', lineHeight: 1 }}>Servidor Offline!</Typography>
                    <Typography style={{ margin: 24, fontSize: '1.5rem', color: theme.theme.palette.text.secondary }}>Aguarde alguns instantes e tente novamente.</Typography>
                    <Typography style={{ margin: 24, fontSize: '1.5rem', color: theme.theme.palette.text.secondary }}>Já estamos trabalhando para restaurar o serviço. Sentimos muito pelo incoveniente.</Typography>
                    <Button variant='contained' color='primary' style={{ margin: 24 }} onClick={doReconnect}>Tentar Novamente</Button>
                </Grid2>

                <Grid2 size={{ xs: 12, sm: 12, md: 4, xl: 4 }} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Construction style={{ maxWidth: 360, maxHeight: 360 }} />
                </Grid2>
                <Grid2 flexGrow={1}></Grid2>
            </Grid2>
        </>
    )
};

export default ServerOffline;
