import { Api, ApiRequestHelper } from './Api';
import Config from '../../../config';
import User from '../User/types';
import { HttpMethods } from '../../types';
import { getStorage, setStorage } from '../Utils';
import { uuidv7 } from 'uuidv7';

export const composeUrl = (baseUrl: string, resource: string, isPublic: boolean, conditions?: string, summarized?: boolean, orderField?: string, sort?: string, page?: number, limit?: number, showingFields?: string[], hidingFields?: string[], token?: boolean, ignorePrivatePrefix?: boolean, showDeleted?: boolean) => {
    const ressourceHasQuery = resource.includes('?');
    let q = '';
    if (page)
        q += (ressourceHasQuery ? '&' : '') + 'page=' + page;
    if (limit)
        q += (q || ressourceHasQuery ? '&' : '') + 'limit=' + limit;
    if (orderField)
        q += (q || ressourceHasQuery ? '&' : '') + 'order=' + orderField;
    if (sort)
        q += (q || ressourceHasQuery ? '&' : '') + 'sort=' + sort;
    if (conditions)
        q += (q || ressourceHasQuery ? '&' : '') + conditions;
    if (!!showingFields && showingFields.length > 0)
        q += (q || ressourceHasQuery ? '&' : '') + "showing=" + showingFields.join('|');
    if (!!hidingFields && hidingFields.length > 0)
        q += (q || ressourceHasQuery ? '&' : '') + "hiding=" + hidingFields.join('|');
    if (summarized)
        q += (q || ressourceHasQuery ? '&' : '') + "summarized=1";
    if (showDeleted)
        q += (q || ressourceHasQuery ? '&' : '') + "showDeleted=1";
    if (!token){
        q += (q || ressourceHasQuery ? '&' : '') + "__tz=" + (new Date()).getTimezoneOffset();
    }
    q = (ressourceHasQuery ? '' : '?') + q;
    return baseUrl + '/' + (isPublic || ignorePrivatePrefix ? '' : 'v1/') + resource + q;
};

export const getOptions = (api: Api, method: string, body?: any, contentType?: string, signal?: AbortSignal): RequestInit => {

    let clientId = getStorage('clientId');
    if (!clientId) {
        clientId = uuidv7();
        setStorage('clientId', clientId);
    }

    if (!contentType)
        contentType = 'application/json';

    if (contentType === 'application/json')
        body = JSON.stringify(body);

    if (contentType === 'none')
        return {
            method: method,
            headers: {
                Authorization: 'Bearer ' + api.token || '',

                ClientId: clientId,
                SessionId: api.sessionId || '',

                'Accept-Language': api.lang || '',
            },
            mode: 'cors',
            body
        };
    return {
        method: method,
        headers: {
            'Content-Type': (contentType || ''),
            Authorization: 'Bearer ' + api.token || '',

            ClientId: clientId,
            SessionId: api.sessionId || '',

            'Accept-Language': (api.lang || ''),
        },
        mode: 'cors',
        body,
        signal
    };
}

const getSecureOptions = (api: Api, method: string, body?: any, contentType?: string, sendToken?: boolean): RequestInit => {

    let clientId = getStorage('clientId');
    if (!clientId) {
        clientId = uuidv7();
        setStorage('clientId', clientId);
    }

    if (!contentType)
        contentType = 'application/json';

    if (contentType === 'application/json')
        body = JSON.stringify(body);
    const headers: any = {
        "x-bg-secure": true
    };
    if (sendToken) {
        headers.Authorization = 'Bearer ' + api.token || '';
    }
    return {
        method: method,
        headers: {
            'Content-Type': contentType,
            ClientId: clientId,

            ...headers
        },
        mode: 'cors',
        credentials: 'include',
        body
    };
}

export const doRequest = async (api: Api, url: string, method: HttpMethods, isPublic: boolean, helper: ApiRequestHelper, body?: any, contentType?: string, redirectOnFail?: boolean, timeout?: number) => {
    const d = new Date();
    if (redirectOnFail === undefined && !isPublic)
        redirectOnFail = true;
    if (d.getTime() > api.expiresAt && !isPublic)
        if (!(await doRefreshToken(api, false)))
            return;
    if (!timeout)
        timeout = 600000;
    const controller = new AbortController();
    const id = setTimeout(() => controller.abort(), timeout);
    let response = await fetch(url, getOptions(api, method, body, contentType, controller.signal));
    clearTimeout(id);
    if (response.status === 429) {
        localStorage.setItem('lastPath', window.location.pathname);
        api.navigate?.('/slowdown');
        return {}
    }
    if (response.status === 403 && redirectOnFail) {
        localStorage.setItem('lastPath', window.location.pathname);
        const uri = url.replace(api.baseUrl, '').split('?')[0];
        localStorage.setItem('disconnectReason', '403 - ' + method + ': ' + uri + '(' + window.location.pathname + ')');
        api.navigate?.('/reconnect');
        // throw await response.json();
        return {}
    }
    if (response.status === 401 && !isPublic) {
        if (!(await doRefreshToken(api, false)))
            return;
        response = await fetch(url, getOptions(api, method, body, contentType))
    }
    if ((response.status === 401 || response.status === 403 || response.status === 404) && isPublic) {
        helper.retry = false;
        let obj
        try {
            obj = await response.json();
        } catch (err) {
            obj = response;
        }
        throw obj;
    }

    if (!response.ok) {
        let obj
        try {
            obj = await response.json();
        } catch (err) {
            let err2 = 'Não foi possível completar a requisição.';
            try {
                err2 = await response.text()
            } catch (err) { /* empty */ }
            throw err2;
        }
        throw obj;
    }
    return await response.json();

};

export const doRequestEx = async (api: Api, url: string, method: string, isPublic: boolean, helper: ApiRequestHelper, body?: any, contentType?: string, redirectOnFail?: boolean, timeout?: number) => {
    const d = new Date();
    if (redirectOnFail === undefined && !isPublic)
        redirectOnFail = true;
    if (d.getTime() > api.expiresAt && !isPublic)
        if (!(await doRefreshToken(api, false))) {
            throw new Error("Cannot refresh token.");
        }
    return fetch(url, getOptions(api, method, body, contentType));
};

export const doSecureRequest = async (api: Api, url: string, method: string, body?: any, contentType?: string, redirectOnFail?: boolean, sendToken?: boolean) => {
    const response = await fetch(url, getSecureOptions(api, method, body, contentType, sendToken));
    if (response.status === 403) {
        if (!redirectOnFail)
            return await response.json();

        const uri = url.replace(api.baseUrl, '');
        localStorage.setItem('disconnectReason', '403 - ' + method + ': ' + uri + '(' + window.location.pathname + ')');
        return api.navigate?.('/reconnect');
    }
    if (!response.ok) {
        throw await response.json();
    }
    return await response.json();
};

export const doRefreshToken = async (api: Api, all: boolean) => {
    let acc = api?.accB64;
    if (!Config.multiDomain)
        acc = api.account;

    const response = await doSecureRequest(api, composeUrl(api.baseUrl, 'token?a=' + acc + (all ? '&all=1' : ''), true, undefined, undefined, undefined, undefined, undefined, undefined, undefined, undefined, true), 'GET', undefined, undefined, true);
    if (!response)
        throw new Error('Invalid refresh token.');
    if (response.token) {
        const d = new Date();
        api.expiresAt = d.getTime() + Math.max((response.expiresIn - 1), 1) * 60 * 1000 - 15000;
        api.token = response.token;
        api.sessionId = response.sessionId;
    }
    const user = new User(response.name);
    user.tenantId = response.accountId;
    user.userId = response.userId;
    user.authenticated = true;
    user.tag = response.tag || response.Tag || 0;
    user.modules = response.modules;
    return user;
}