import { Backdrop, Box, Card, CircularProgress, Grid2, IconButton, Modal, Typography } from '@mui/material';
import { Close } from '@mui/icons-material';
import React, { CSSProperties, Dispatch, useEffect, useState } from 'react';

import { DialogModalButton, DialogModalSize } from '../../../services/DialogModal/types';
import { useLang } from '../../../services/Lang';
import useStyles from './style';
import { DialogHelper } from '../../../services/Dialog';
import ColorButton from '../ColorButton';
import { useTheme } from '@mui/material/styles';

import { useDraggable } from "@dnd-kit/core";
import { CSS } from '@dnd-kit/utilities';

interface IDialogModal {
    ref?: any;
    hash: string;
    caption: string;
    open: boolean;
    setOpen: Dispatch<boolean>;
    classes: any;
    size?: DialogModalSize;
    onSubmit?: () => any;
    autoClose?: boolean;
    buttons?: DialogModalButton[];
    fullHeight?: boolean;
    noCloseButton?: boolean;
    helper?: DialogHelper;
    children?: any;
}

const DialogModal: React.FC<IDialogModal> = ({ ref, hash, caption, open, setOpen, onSubmit, classes, size, children, autoClose, buttons, fullHeight, noCloseButton, helper }) => {

    const { lang } = useLang();
    const theme = useTheme();
    // const navigate = useNavigate();
    const dialogClasses = useStyles();

    const [isOpen, setIsOpen] = useState(window.location.hash === hash);

    const btnOk = !buttons || buttons.includes('ok');
    const btnCancel = !buttons || buttons.includes('cancel');
    const btnClose = !!buttons && buttons.includes('close');

    const hasButtons = btnOk || btnCancel || btnClose;

    const [submitting, setSubmitting] = useState(false);
    if (helper)
        helper.setSubmitting = setSubmitting;

    const handleClose = () => {
        setSubmitting(false);
        setOpen(false);
        setIsOpen(false);
    };

    useEffect(() => {
        if (open) {
            window.location.hash = hash;
            return
        }
        if (window.location.hash === '#' + hash) {
            window.location.hash = '';
            handleClose()
            return
        }
    }, [open]);


    const onCancel = async () => {
        if (helper && helper.onCancel) {
            if (await helper.onCancel() === false)
                return;
        }
        handleClose();
    }

    useEffect(() => {
        const onHashChange = () => {
            const o = window.location.hash === '#' + hash;
            if (!o) return handleClose()
            setIsOpen(o);
            setOpen(o)
        };
        window.addEventListener("hashchange", onHashChange);
        return () => {
            window.removeEventListener("hashchange", onHashChange);
        };
    }, []);

    const getClass = (): string => {
        switch (size) {
            case "small":
                return dialogClasses.ModalSmall
            case "medium":
                return dialogClasses.ModalMedium
            case "large":
                return dialogClasses.ModalLarge;
            case "full":
                return dialogClasses.ModalFull;
        }
        return dialogClasses.ModalCustom
    }

    const okClick = async (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        if (helper && helper.onOk) {
            if (await helper.onOk() === false)
                return;
        }
        setSubmitting(true);

        e?.preventDefault();
        if (onSubmit) {
            try {
                if ((await onSubmit()) === false) {
                    setSubmitting(false);
                    return;
                }
            } catch (err) {
                setSubmitting(false);
                return;
            }

        }
        if (autoClose !== false)
            handleClose();
    }


    const { attributes, listeners, transform, isDragging, setNodeRef } = useDraggable({
        id: 'dialog',
    });

    const draggableStyle = {
        transform: CSS.Translate.toString(transform)
    };

    return (
        <Modal
            ref={ref}
            open={isOpen}
            onClose={handleClose}
            BackdropComponent={Backdrop}
            BackdropProps={{ timeout: 250, onClick: (evt) => { evt.preventDefault(); } }}
            disableScrollLock
            className={classes?.['Modal_' + hash]}
            disableEscapeKeyDown={noCloseButton}
            onKeyDown={(event) => {
                if ((event.target as any)?.tagName === 'TEXTAREA')
                    return;
                if (event.key === 'Enter') {
                    okClick()
                }
                if (event.key === 'Escape') {
                    onCancel()
                }
            }}
        >
            <Box className={getClass()} >
                <Card className={dialogClasses.Card} id={'modal-root-' + hash} {...attributes} style={draggableStyle}>

                    <Grid2 padding={1} paddingBottom={0.5} className={dialogClasses.Header}>
                        <div ref={setNodeRef} style={{ width: '100%', cursor: isDragging ? 'grabbing' : 'grab' }}  {...listeners}>
                            <Typography variant="h4">{caption}</Typography>
                        </div>
                        {!noCloseButton &&
                            <IconButton
                                aria-label="Close"
                                size="small"
                                onClick={onCancel}
                            >
                                <Close />
                            </IconButton>}
                    </Grid2>

                    <Grid2 className={dialogClasses.ContentRoot + ' BG-Dialog-Content-Root ' + (fullHeight ? dialogClasses.ContainerRootFullHeight : '')} style={{ paddingBottom: hasButtons ? 58 : 0 }}>
                        <Grid2 className={dialogClasses.Content + ' BG-Dialog-Content-Root ' + (fullHeight ? dialogClasses.ContainerFullHeight : '')} >

                            <Grid2 container spacing={2} padding={2} paddingTop={0} className={dialogClasses.ContentInsideFullHeight} >
                                {children}
                            </Grid2>

                        </Grid2>
                    </Grid2>

                    {hasButtons &&
                        <Grid2 padding={2} paddingTop={1} className={dialogClasses.Footer} >
                            <Grid2 container spacing={1} size={12} justifyContent="flex-end">
                                {btnOk &&
                                    <ColorButton onClick={okClick} color="green" disabled={submitting}>
                                        {submitting && <CircularProgress size={14} style={{ marginRight: 6 }} />}
                                        {lang.data.captions.ok}
                                    </ColorButton>
                                }
                                {btnCancel && <ColorButton onClick={onCancel} variant="outlined" disabled={submitting}>{lang.data.captions.cancel}</ColorButton>}
                                {btnClose && <ColorButton onClick={onCancel} variant="outlined" disabled={submitting}>{lang.data.captions.close}</ColorButton>}
                            </Grid2>
                        </Grid2>
                    }
                </Card>
            </Box>
        </Modal>
    );
}

export default DialogModal;