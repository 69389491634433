import { makeStyles, createStyles } from '@mui/styles';
import { CustomTheme } from '../../../services/Theme/types';

const useStyles = makeStyles((theme: CustomTheme) =>
    createStyles({
        root: {
            width: '100% !important',
            paddingTop: '0 !important',
        }
    }),
);

export default useStyles;